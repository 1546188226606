<template>
  <div class="block monthResult">
    <h3 class="monthResult__title">Last month result</h3>

    <p class="monthResult__value">-130'000€</p>

    <button class="monthResult__button">
      <span class="icon icon-download"></span>
      <span>See invoice</span>
    </button>
  </div>
</template>

<script src="./script.js"></script>
<style src="./style.less" lang="less"></style>
