<template>
  <div class="block account">
      <h4 class="account-title">{{content.name}}</h4>
      <div class="account-content">
        <div class="account-value">{{intFormater(content.value)}}</div>
        <div class="input-container" v-if="content.depositInput">
          <input type="checkbox" :checked="content.deposit">
          <div class="custom-input"></div>
          <h4>Deposit</h4>
        </div>
      </div>
      <Card :infos="cardData" />
  </div>
</template>

<script src="./script.js"></script>
<style src="./style.less" lang="less"></style>
