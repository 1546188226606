import ViewMixin from '../../js/viewMixin'
import { mapActions } from 'vuex'

export default {
  name: 'settings',
  mixins: [ViewMixin],
  data () {
    return {
      password: '',
      oldPassword: ''
    }
  },
  methods: {
    ...mapActions('changePassword', {
      newPassword: 'newPassword'
    }),
    handleLogin () {
      this.newPassword({
        password: this.password,
        oldPassword: this.oldPassword
      })
    }
  }
}
