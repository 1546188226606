<template>
  <div
  class="financial-view-modal is-page"
>
  <!-- {{ missions }} -->
  <FlightDetails :content="missionsWithEntries">
    My Flight Details
  </FlightDetails>
</div>
</template>

<script>
import FlightDetails from '@/components/FlightDetails'

import { mapGetters } from 'vuex'

export default {
  name: 'ListFlights',
  components: {
    FlightDetails
  },
  computed: {
    ...mapGetters('missions', {
      missions: 'missions'
    }),
    ...mapGetters('financialDetails', {
      financialDetailsByMonth: 'financialDetailsByMonth'
    }),
    missionsWithEntries () {
      const data = this.missions

      // check for all missions if leon.flight_number is present
      // if yes, add entries from financialDetailsByMonth[].data.missions[leon.flight_number] if no KFE return null
      data.forEach(element => {
        if (element.leon.flight_number) {
          for (const key in this.financialDetailsByMonth) {
            if ('missions' in this.financialDetailsByMonth[key].data.report && this.financialDetailsByMonth[key].data.report.missions[element.leon.flight_number]) {
              element.entries = this.financialDetailsByMonth[key].data.report.missions[element.leon.flight_number].entries
            }
          }
        }
      })

      return data
    }
  }
}
</script>
