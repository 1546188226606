import feathers from '../../feathers'

export default {
  namespaced: true,
  state: {
    userId: undefined,
    accessToken: undefined,
    user: undefined
  },
  getters: {
    user: (state) => state.userId ? state.user : undefined
  },
  actions: {
    async login ({ commit, dispatch }, { email, password } = {}) {
      let auth
      if (email && password) {
        auth = {
          strategy: 'local',
          email,
          password
        }
      }
      return feathers.client.authenticate(auth)
        .then(({ user, accessToken }) => {
          commit('setAccessToken', accessToken)
          commit('setUserId', user._id)
          commit('setUser', user)
          return Promise.all([
            dispatch('aircrafts/find', null, { root: true }),
            dispatch('missions/find', null, { root: true }),
            dispatch('financialDetails/find', null, { root: true }),
            dispatch('reports/find', null, { root: true })
          ])
        })
    },
    async logout ({ commit }) {
      commit('setUserId', undefined)
      return feathers.client.logout()
    }
  },
  mutations: {
    setUserId (state, userId) {
      state.userId = userId
    },
    setUser (state, user) {
      state.user = user
    },
    setAccessToken (state, accessToken) {
      state.accessToken = accessToken
    }
  }
}
