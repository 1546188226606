<template>
  <div class="view settings">

    <div class="content">
      <section class="column">
        <h2>Account settings</h2>

        <div class="form-group">
          <div class="input-block">
            <label for="username">First Name</label>
            <input type="text" name="username">
          </div>
          <div class="input-block">
            <label for="password">Last name</label>
            <input type="text" name="password">
          </div>
        </div>
        <div class="input-block">
          <label for="email">Email</label>
          <input type="email" name="email">
        </div>
        <!-- <div class="input-block">
          <label for="public_name">Public name</label>
          <input type="text" name="public_name">
        </div>
        <span class="infos">Specifies how your name will appear in the account section and in notices</span> -->
      </section>
      <section class="column">
        <h2>Changing password</h2>
        <div class="input-block">
          <label for="current_password">Actual password</label>
          <input type="password" name="current_password" v-model="oldPassword">
        </div>
        <div class="input-block">
          <label for="new_password">New password</label>
          <input type="password" name="new_password" v-model="password">
        </div>
        <div class="input-block">
          <label for="new_password_confirm">confirm password</label>
          <input type="password" name="new_password_confirm">
        </div>
        <button @click="handleLogin">
          save
        </button>
      </section>
    </div>
  </div>
</template>

<script src="./script.js"></script>
<style src="./style.less" lang="less"></style>
