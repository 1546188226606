import 'keen-slider/keen-slider.min.css'
import KeenSlider from 'keen-slider'

import componentMixin from '../../js/componentMixin'

export default {
  name: 'Slider',
  mixins: [componentMixin],
  props: {
    title: {
      type: String,
      default () {
        return 'Photos'
      }
    },
    images: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data: function () {
    return {
      slider: null,
      current: 0
    }
  },
  mounted () {
    this.slider = new KeenSlider(this.$refs.slider, {
      mode: 'free-snap',
      slides: {
        initial: this.current,
        // origin: 'center',
        perView: window.innerWidth > 813 ? 2.5 : 1,
        spacing: 30
      },
      detailsChanged: (s) => {
        this.current = s.track.details.abs
      }
    })
  },
  methods: {
    goTo (i = 0) {
      this.slider.moveToIdx(i, false, { duration: 800 })
    }
  },
  beforeUnmount () {
    if (this.slider) this.slider.destroy()
  }
}
