import Card from '../Card'

import ViewMixin from '../../js/viewMixin'
import ComponentMixin from '../../js/componentMixin.js'

import { openPdf } from '../../helpers'

export default {
  name: 'report item',
  mixins: [
    ViewMixin,
    ComponentMixin
  ],
  components: {
    Card
  },
  props: {
    content: {}
  },
  mounted () {
    this.mainItems = this.$el.querySelectorAll('.report-item-maincontent')
    this.subItems = this.$refs.sub
    this.$nextTick(() => {
      this.subItems.size = this.$refs.sub.querySelector('.container-sub').getBoundingClientRect().height
    })

    // this.checkSize()
  },
  methods: {
    checkSize () {

    },
    openAll () {
      this.content.files.forEach(file => {
        if (file.pdf) {
          openPdf(file.pdf)
        }
      })
    }
  }
}
