<template>
  <div id="nav" class="nav">
    <div class="nav-container">
      <router-link class="nav-links nav-links-logo " to="/" ><img :src="logo" alt=""></router-link>
      <div class="nav-links-block">
      <router-link class="nav-links" to="/"><span class="icon icon-plane"></span><span class="title">My Aircraft</span></router-link>
      <router-link class="nav-links" to="/financial-details"><span class="icon icon-recap"></span><span class="title">My Financial Details</span></router-link>
      <router-link class="nav-links" to="/flights-details"><span class="icon icon-WarehouseIcon"></span><span class="title">My Flights Details</span></router-link>
      <!-- <router-link class="nav-links" to="/reports"><span class="icon icon-Accounting"></span> <span class="title">  My Reports</span></router-link> -->
      <router-link class="nav-links settings" to="/settings"><span class="icon icon-settings"></span></router-link>
      <a class="nav-links logout" @click="_logout">Logout</a>
      </div>
    </div>
  </div>
</template>

<script src="./script.js"></script>
<style src="./style.less" lang="less"></style>
