<template>
  <div class="block accountStatus">
    <h3 class="accountStatus__title">My Briefing</h3>

    <div class="accountStatus__content">
      <div class="content__left">
        <h4 class="left__title">Account Status</h4>
        <div class="left__deposit deposit">
          <span></span> Deposit
        </div>
      </div>

      <p class="content__value">-130'000€</p>

      <div class="content__right">
        <div class="right__deposit deposit">
          <span></span> Deposit
        </div>

        <button class="right__details">
          <span class="icon icon-download"></span>
          <span>See details</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script src="./script.js"></script>
<style src="./style.less" lang="less"></style>
