<template>
  <div class="block overview">
    <h3>Year overview</h3>
    <ul class="overview-states">
      <li class="overview-states-item" v-for="(graphData, name, index) in dataGraphs" :key="name">
        <button
          :class="{
            active: graphDisplay.includes(name)
          }"
          :style="'color:' + (graphData.options?.borderColor || null)"
          @click="changeData(name, index)"
        >
          <h4>{{graphData.title}}</h4>
          <p>{{graphData.value}}</p>
        </button>
      </li>
    </ul>

    <GraphLine :dataset="currentDataset" :datas="dataGraphs" :datasDisplay="graphDisplay"/>

  </div>
</template>

<script src="./script.js"></script>
<style src="./style.less" lang="less"></style>
