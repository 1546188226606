import feathers from '../../feathers'

export default {
  namespaced: true,
  state: {
    fetched: false,
    aircrafts: [],
    selectedAircraft: undefined
  },
  getters: {
    currentAircraft: (state) => state.selectedAircraft ? state.aircrafts.find(aircraft => aircraft._id === state.selectedAircraft) : {}
  },
  actions: {
    async find ({ state, commit }) {
      if (!state.fetched) {
        const aircrafts = await feathers.client.service('api/my-plane/aircrafts').find()
        commit('setAircrafts', aircrafts)
      }
    }
  },
  mutations: {
    setAircrafts (state, aircrafts) {
      state.fetched = true
      state.aircrafts = aircrafts
      if (!state.selectedAircraft && aircrafts.length) {
        state.selectedAircraft = aircrafts[0]._id
      }
    }
  }
}
