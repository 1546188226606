<template>
  <router-link :to="infos.to" :class="{ 'card': true, 'unseen': infos.unseen }">
    <div :class="[infos.type, 'container']">
      <span class="icon icon-download"></span>
      <div class="content">
        <h4>{{ infos.name }}</h4>
        <p v-if="infos.type === 'dated' && infos.date" class="card-date">
          Valid until {{ infos.date }} <span class="icon icon-Check"></span>
        </p>
        <p v-if="infos.type === 'flight' && infos.flight" class="card-flight">{{ infos.flight }}</p>
      </div>
    </div>
  </router-link>
</template>

<script src="./script.js"></script>
<style src="./style.less" lang="less"></style>
