<template>
  <div class="view briefing-view">
    <!-- <Heading>My Briefing</Heading> -->
    <div v-if="!isMobile">
      <div class="content-view">
        <section>
          <AccountStatus />
          <!-- <ul class="account-list">
            <li v-for="(item, id) in account" :key="id">
              <Account :content="item" />
            </li>
          </ul> -->
          <div class="content-view__half">
            <MonthResult />
            <CardList v-if="reports" :cards="reports" class="-lineTitle">Reports</CardList>
          </div>
        </section>
        <section>
          <Calendar :infos="missions"/>
        </section>
      </div>
      <Overview :content="overview" />
    </div>
    <div class="content-view" v-else>
      <AccountStatus />
      <!-- <ul class="account-list">
        <li v-for="(item, id) in account" :key="id">
          <Account :content="item" />
        </li>
      </ul> -->
      <MonthResult />
      <CardList v-if="reports" :cards="reports" class="-lineTitle">Reports</CardList>
      <Overview :content="overview" />
      <Calendar :infos="missions"/>
    </div>
  </div>
</template>

<script src="./script.js"></script>
<style src="./style.less" lang="less"></style>
