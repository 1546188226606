<template>
  <div class="block card-list">
    <h3><slot></slot></h3>

    <ul class="card-list-list">
      <li class="card-list-list-item" v-for="(item, id) in cards" :key="id">
        <Card :infos="item" />
      </li>
    </ul>
  </div>
</template>

<script src="./script.js"></script>
<style src="./style.less" lang="less"></style>
