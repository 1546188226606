import moment from 'moment'

import feathers from '../../feathers'

export default {
  namespaced: true,
  state: {
    fetched: false,
    reports: {}
  },
  getters: {
    reportsByMonth: (state, getters, rootState, rootGetters) => {
      if (!rootGetters['aircrafts/currentAircraft']) return {}

      const reports = (state.reports.reports || []).filter(report => report.aircraft === rootGetters['aircrafts/currentAircraft']._id)
      const additionalReports = (state.reports.additionalReports || []).filter(report => report.aircraft === rootGetters['aircrafts/currentAircraft']._id)
      const reportsByMonth = {}
      reports.forEach(report => {
        if (!reportsByMonth[report.year] && report.year) {
          reportsByMonth[report.year] = {}
        }
        if (!reportsByMonth[report.year][report.month.toString().padStart(2, '0')]) {
          reportsByMonth[report.year][report.month.toString().padStart(2, '0')] = {
            date: moment(`${report.year}${report.month}`, 'YYYYMM'),
            result: 0,
            remark: '',
            files: []
          }
        }
        switch (report.file_type) {
          case 'financial':
            report.name = 'Financial report'
            break
          case 'additional':
            report.name = 'Additional report'
            break
        }

        reportsByMonth[report.year][report.month.toString().padStart(2, '0')].files.push(report)
      })

      additionalReports.forEach(report => {
        if (!reportsByMonth[report.year] && report.year) {
          reportsByMonth[report.year] = {}
        }
        if (!reportsByMonth[report.year][report.month.toString().padStart(2, '0')]) {
          reportsByMonth[report.year][report.month.toString().padStart(2, '0')] = {
            date: moment(`${report.year}${report.month}`, 'YYYYMM'),
            result: 0,
            remark: '',
            files: []
          }
        }
        switch (report.file_type) {
          case 'financial':
            report.name = 'Financial report'
            break
          case 'additional':
            report.name = 'Additional report'
            reportsByMonth[report.year][report.month.toString().padStart(2, '0')].result = report.data.turnover
            break
        }
        reportsByMonth[report.year][report.month.toString().padStart(2, '0')].files.push(report)
      })

      const years = Object.keys(reportsByMonth).sort((a, b) => {
        if (a > b) return -1
        if (a < b) return 1
        return 0
      })
      const sortedReportsByMonth = []
      years.forEach(year => {
        const months = Object.keys(reportsByMonth[year]).sort((a, b) => {
          if (a > b) return -1
          if (a < b) return 1
          return 0
        })
        months.forEach(month => {
          if (!sortedReportsByMonth[year]) {
            sortedReportsByMonth[year] = []
          }
          sortedReportsByMonth[year][month] = reportsByMonth[year][month]
        })
      })
      return sortedReportsByMonth
    },
    reportsByMonthPlane: (state, getters, rootState, rootGetters) => {
      if (!rootGetters['aircrafts/currentAircraft']) return {}

      const reports = (state.reports.reports || []).filter(report => report.aircraft === rootGetters['aircrafts/currentAircraft']._id)
      const additionalReports = (state.reports.additionalReports || []).filter(report => report.aircraft === rootGetters['aircrafts/currentAircraft']._id)

      const reportsByMonth = {}
      reports.forEach(report => {
        if (!reportsByMonth[`${report.year}${report.month.toString().padStart(2, '0')}`]) {
          reportsByMonth[`${report.year}${report.month.toString().padStart(2, '0')}`] = {
            date: moment(`${report.year}${report.month}`, 'YYYYMM'),
            result: 0,
            remark: '',
            files: []
          }
        }
        switch (report.file_type) {
          case 'financial':
            report.name = 'Financial report'
            break
          case 'additional':
            report.name = 'Additional report'
            break
        }
        reportsByMonth[`${report.year}${report.month.toString().padStart(2, '0')}`].files.push(report)
      })
      additionalReports.forEach(report => {
        if (!reportsByMonth[`${report.year}${report.month.toString().padStart(2, '0')}`]) {
          reportsByMonth[`${report.year}${report.month.toString().padStart(2, '0')}`] = {
            date: moment(`${report.year}${report.month}`, 'YYYYMM'),
            result: 0,
            remark: '',
            files: []
          }
        }
        switch (report.file_type) {
          case 'financial':
            report.name = 'Financial report'
            break
          case 'additional':
            report.name = 'Additional report'
            reportsByMonth[`${report.year}${report.month.toString().padStart(2, '0')}`].result = report.data.turnover
            break
        }
        reportsByMonth[`${report.year}${report.month.toString().padStart(2, '0')}`].files.push(report)
      })

      const months = Object.keys(reportsByMonth).sort((a, b) => {
        if (a > b) return -1
        if (a < b) return 1
        return 0
      })

      const sortedReportsByMonth = []
      months.forEach(month => {
        sortedReportsByMonth.push(reportsByMonth[month])
      })

      return sortedReportsByMonth
    }
  },
  actions: {
    async find ({ state, commit }) {
      if (!state.fetched) {
        const reports = await feathers.client.service('api/my-plane/reports').find()
        commit('setReports', reports)
      }
    }
  },
  mutations: {
    setReports (state, reports) {
      state.fetched = true
      state.reports = reports
    }
  }
}
