<template>
  <div class="block map-block">
    <div class="map-block-top">
      <h3>Maps</h3>
      <FromTo />
    </div>
    <div id="map" class="map-block-container"></div>
  </div>
</template>

<script src="./script.js"></script>
<style src="./style.less" lang="less"></style>
