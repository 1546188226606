import ReportItem from '../../components/ReportItem'
import ViewMixin from '../../js/viewMixin'

import { mapGetters } from 'vuex'

export default {
  name: 'reports',
  components: {
    ReportItem
  },
  mixins: [ViewMixin],
  computed: {
    ...mapGetters('reports', {
      reportsByMonthPlane: 'reportsByMonthPlane'
    })
  }
}
