<template>
  <div class="block login-block">

    <form action="">
       <div><img :src="logo" alt=""></div>
      <div class="login input-block">
        <label for="login">Login</label>
        <input id="login" type="text">
      </div>
       <div class="pwd input-block">
        <label for="pwd">Password</label>
        <input id="pwd" type="password">
      </div>
      <a href="">Forgot password</a>
      <div class="submit-container">
      <input type="submit">
      </div>
    </form>
  </div>
</template>

<script src="./script.js"></script>
<style src="./style.less" lang="less"></style>
